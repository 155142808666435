<template>
    <div id="SynchronousPractice">
        <div name="downloadfile" @click="goDownloadQuestions">下载</div>
        <van-overlay :show="isOpen" @click="isOpen = false">
            <div class="wrapper" @click.stop>
                <van-image src="/weixintishi04.png"></van-image>
            </div>
        </van-overlay>
    </div>
</template>

<script>

import common from "../../api/common";
import {downloadFile} from '../../utils/downloadFile'

export default {
    name: "DownloadQuestion",
    data() {
        return {
            isOpen: false,
            test_id: 0,
            classList: []
        }
    },
    mounted(){
        this.test_id = this.$route.query.testId
        console.log("mounted test_id",this.test_id,this.$route.query.testId)

        this.goDownloadQuestions();
    },
    methods: {
        goDownloadQuestions() {
            if (this.test_id === 0 || this.test_id === '0'){
                return;
            }
            // 判断是否是微信内
            var u = navigator.userAgent;
            var isWeixin = !!/MicroMessenger/i.test(u);
            if (isWeixin) {
                // 控制蒙层
                this.isOpen = true
                return;
            }
            var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;
            // var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (isAndroid) {
                // android
                // window.location.href = '/api/downloadTestPaperQuestions?testId=' + test_id.value + '&paperSize=
            }
            let params = {
                fileType : 'pdf',
                testId : this.test_id,
                paperSize : 1,
                paperHeader : 0,
                paperFooter : 0,
                choiceBorder : 0,
                titleDot : 0,
                snoFrameNumber : 0,
            }
            common.downloadTestPaperQuestions(params).then((res) => {
                if (res.success){
                    // window.location.href = res.data
                    // window.location.href = '/api/downloadTestPaperQuestions?testId=' + test_id.value + '&paperSize=
                    downloadFile(res.data.url,res.data.fileName, '正在获取文件')
                }
            })
        },
    }
}
</script>
<style lang="scss">
</style>
